<template>
	<div style="margin-left: 2%">
		<!--选择图号-->
		<div>
			<el-tag style="margin-right: 1%;">图&emsp;号:</el-tag>
			<el-select
				size="small"
				v-model="drawing_no"
				filterable
				@change="getTypeConfig"
				style="width: 30%"
			>
				<el-option
					v-for="item in drawing_nos"
					:key="item.id"
					:label="'图号:' + item.drawingNo + '->' + '衬砌类型:' + item.supportNo"
					:value="item.id"
				/>
			</el-select>
		</div>

		<!--选择加宽值-->
		<div style="margin-top: 2%">
			<el-tag style="margin-right: 1%;">加宽值:</el-tag>
			<el-select
				size="small"
				v-model="widen_value"
				filterable
				@change="changeWiden"
				style="width: 30%"
			>
				<el-option
					v-for="item in widen_values"
					:key="item"
					:label="item"
					:value="item"
				/>
			</el-select>
		</div>
	<!--	支护参数详细数据-->
		<div>
			<el-table :data="type_config_num" style="width: 90%">
				<el-table-column prop="typeId" label="配置项" width="650" />
				<el-table-column prop="wideningValue" label="加宽值" width="150" />
				<el-table-column prop="numValue" label="数值" width="250" />
				<el-table-column prop="upUser" label="上传人" width="150" />
				<el-table-column prop="upTime" label="上传时间" width="280" />
			</el-table>
			<!--<vxe-table-->
			<!--	:align="allAlign"-->
			<!--	:data="type_config_num">-->
			<!--	<vxe-column field="typeId" title="配置项"></vxe-column>-->
			<!--	<vxe-column field="wideningValue" title="加宽值"></vxe-column>-->
			<!--	<vxe-column field="numValue" title="数值"></vxe-column>-->
			<!--	<vxe-column field="upUser" title="上传人"></vxe-column>-->
			<!--	<vxe-column field="upTime" title="上传时间"></vxe-column>-->
			<!--</vxe-table>-->
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowTimes} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			drawing_no: '',
			drawing_nos: [],
			widen_values: [],
			widen_value: '',
			type_config_num: [],
			allAlign: 'right'
		}
	},
	created() {
		this.getAllDrawing()
	},
	methods: {
		// 获取所有的图号
		getAllDrawing(){
			this.axios.get('/quantitieType/getAllTypeByDrawNo', (response) => {
				this.drawing_nos = response.obj
			},{
				drawing_no: this.drawing_no
			})
		},
		getTypeConfig(){
			this.axios.get('/quantitieNum/getConfigWiden', (response) => {
				if (response.code === 200){
					this.widen_values = response.obj
				}
			},{
				type_id: this.drawing_no
			})
		},
		changeWiden(){
			this.axios.get('/quantitieNum/getMessByTypeWiden', (response) => {
				this.type_config_num = response.obj
			},{
				type_id: this.drawing_no,
				widen_value: this.widen_value
			})
		}
	}
}
</script>

<style>

</style>