<template>
	<div>
		<!--选择图号-->
		<div>
			<el-tag style="margin-right: 1%;">图号:</el-tag>
			<el-select
				size="small"
				v-model="drawing_no"
				filterable
				style="width: 30%"
				@change="changeTypeId"
			>
				<el-option
					v-for="item in drawing_nos"
					:key="item.id"
					:label="'图号:' + item.drawingNo + '->' + '衬砌类型:' + item.supportNo"
					:value="item.id"
				/>
			</el-select>
			<el-button v-if="config_flag" type="danger" size="small" style="margin-left: 50%" @click="saveTypeConfig">保存并提交</el-button>
			<el-button v-if="!config_flag" disabled type="danger" size="small" style="margin-left: 50%" @click="saveTypeConfig">保存并提交</el-button>
		</div>

		<el-divider />
		<!--选择配置-->
		<div style="margin-top: 1%">
			<el-tag style="margin-right: 1%;">配置:</el-tag>
			<el-select
				v-model="config1"
				filterable
				style="width: 20%"
				@change="changeConfig1"
				placeholder="请选择第一层级"
			>
				<el-option
					v-for="item in configs1"
					:key="item.id"
					:label="item.name"
					:value="item.id + '?' + item.name"
				/>
			</el-select>

			<el-select
				v-model="config2"
				filterable
				style="width: 20%"
				@change="changeConfig2"
				placeholder="请选择第二层级"
			>
				<el-option
					v-for="item in configs2"
					:key="item.id"
					:label="item.name"
					:value="item.id + '?' + item.name"
				/>
			</el-select>

			<el-select
				v-model="config3"
				filterable
				style="width: 20%"
				placeholder="请选择第三层级"
			>
				<el-option
					v-for="item in configs3"
					:key="item.id"
					:label="item.name"
					:value="item.id + '?' + item.name"
				/>
			</el-select>
			<el-button type="primary" style="margin-left: 5%" @click="addConfig">添加</el-button>
		</div>
	<!--	选择好的配置-->
		<div>
			<el-table :data="configData" style="width: 100%">
				<el-table-column prop="name" label="配置详情" width="380" />
				<el-table-column>
					<template #default="scope">
						<el-button type="danger" @click="delConfig(scope)"> 删除 </el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {ElMessage} from "element-plus";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			drawing_no: '',
			drawing_nos: [],
			loading: false,
			configs1: [],
			config1: '',
			configs2: [],
			config2: '',
			configs3: [],
			config3: '',
			configData: [],
			config_ids: [],
			config_flag: true
		}
	},
	created() {
		this.getAllDrawing()
		this.getAllConfig()
	},
	methods: {
		// 获取所有的图号
		getAllDrawing(){
			this.axios.get('/quantitieType/getAllTypeByDrawNo', (response) => {
				this.drawing_nos = response.obj
			},{
				drawing_no: this.drawing_no
			})
		},
		changeTypeId(){
			this.axios.get('/quantitieTypeConfig/getTypeConfig', (response) => {
				if (response.obj.length > 0) {
					this.config_flag = false
					ElMessage.error('该衬砌类型已配置，请勿重复配置!')
				}else {
					this.config_flag = true
				}
			},{
				type_id: this.drawing_no
			})
		},
		// 获取第一层
		getAllConfig(){
			this.axios.get('/quantiteConfig/getConfig', (response) => {
				this.configs1 = response.obj
			},{
				depth: 1
			})
		},
		changeConfig1(){
			this.axios.get('/quantiteConfig/getConfig', (response) => {
				this.configs2 = response.obj
			},{
				depth: 2,
				id: this.config1.split('?')[0]
			})
		},
		changeConfig2(){
			this.axios.get('/quantiteConfig/getConfig', (response) => {
				this.configs3 = response.obj
			},{
				depth: 3,
				id: this.config2.split('?')[0]
			})
		},
		addConfig(){
			this.axios.get('/quantiteConfig/getAllDepth', (response) => {
				this.configData.push(response.obj)
			},{
				config1: this.config1,
				config2: this.config2,
				config3: this.config3
			})
		},
		delConfig(item){
			this.configData.splice(item.$index, 1)
		},
		saveTypeConfig(){
			if (this.drawing_no === ''){
				ElMessage.error('请选择图号!')
			}else if (this.configData.length === 0){
				ElMessage.error('请选择相关配置!')
			}else {
				for (let i = 0 ; i < this.configData.length; i++){
					this.config_ids.push(this.configData[i].parentId)
				}
				this.axios.post('/quantitieTypeConfig/saveTypeConfig', (response) => {
					if (response.obj){
						ElMessage.success('该图号配置成功!')
						this.reload()
					}
				},{
					type_id: this.drawing_no,
					config_ids: this.config_ids
				})
			}

		}
	}
}
</script>

<style>

</style>