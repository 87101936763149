<template>
	<div>
		<div style="margin-top: 5%;margin-left: 30%">
			<el-select
				v-model="config1"
				filterable
				style="width: 30%"
				@change="changeConfig1"
				placeholder="请选择第一层级"
			>
				<el-option
					v-for="item in configs1"
					:key="item.id"
					:label="item.name"
					:value="item.id"
				/>
			</el-select>
		</div>
		<div style="margin-top: 2%;margin-left: 30%">
			<el-select
				v-model="config2"
				filterable
				style="width: 30%"
				placeholder="请选择第二层级"
			>
				<el-option
					v-for="item in configs2"
					:key="item.id"
					:label="item.name"
					:value="item.id"
				/>
			</el-select>
		</div>
		<div style="margin-left: 30%;margin-top: 2%">
			<el-input placeholder="请输入第三层级名称" v-model="config_name" style="width: 30%"/>
		</div>
		<div style="margin-left: 30%;margin-top: 2%">
			<el-input placeholder="请输入单位" v-model="unit" style="width: 30%"/>
		</div>
		<div style="margin-left: 30%;margin-top: 2%">
			<el-button @click="saveConfig" style="margin-left: 2%" type="primary" >提交</el-button>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			config1: '',
			configs1: [],
			config2: '',
			configs2: [],
			config_name: '',
			unit: ''
		}
	},
	created() {
		this.getOneConfig()
	},
	methods: {
		getOneConfig(){
			this.axios.get('/quantiteConfig/getConfig', (response) => {
				this.configs1 = response.obj
			},{
				depth: 1
			})
		},
		changeConfig1(){
			this.axios.get('/quantiteConfig/getConfig', (response) => {
				this.configs2 = response.obj
			},{
				depth: 2,
				id: this.config1
			})
		},
		saveConfig(){
			this.axios.post('/quantiteConfig/saveConfig', (response) => {
				if (response.obj){
					ElMessage.success('添加成功!')
					this.config_name = ''
					this.unit = ''
				}else {
					ElMessage.error('添加失败!')
				}
			},{
				name: this.config_name,
				flag: 3,
				parent_id: this.config2,
				unit: this.unit
			})
		}
	}
}
</script>

<style>

</style>