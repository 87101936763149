<template>
	<div>
	<!--	搜索、新增按钮-->
		<div style="display: flex">
			<el-input v-model="drawing_no" style="width: 20%;margin-right: 5%" placeholder="请输入图号" @change="get_type" clearable/>
			<el-input v-model="support_no" style="width: 25%;margin-right: 5%" placeholder="请输入支护参数类型" @change="get_type"  clearable/>
			<el-button type="primary" @click="addTypeDialogVisible = true">新增</el-button>
		</div>

	<!--	支护参数列表-->
		<div style="min-height: 70vh">
			<el-table :data="types" style="width: 90%">
				<el-table-column prop="drawingNo" label="参考图号" width="300" />
				<el-table-column prop="supportNo" label="支护参数类型" width="300" />
				<el-table-column prop="upUser" label="上传者" width="300" />
				<el-table-column prop="upTime" label="上传时间" width="300" />
				<el-table-column prop="v" label="操作" width="300">
					<template #default="scope">
						<el-button type="primary" @click="updataDrawingNo(scope.row)"> 新增图号 </el-button>
						<el-button type="warning" @click="updataTypeBut(scope.row)"> 修改 </el-button>
						<el-popconfirm
							width="220"
							confirm-button-text="确认"
							cancel-button-text="取消"
							title="是否确认删除?"
							@confirm="del_message(scope.row)"
						>
							<template #reference>
								<el-button type="danger"> 删除 </el-button>
							</template>
						</el-popconfirm>

					</template>
				</el-table-column>
			</el-table>
		</div>

	<!--	分页-->
		<div style="float: right;margin-top: 20px;">
			<el-pagination v-model:currentPage="pagenum"
			               :small="true"
			               background layout="prev, pager, next" :total="totals">
			</el-pagination>
		</div>


	<!--	新增弹窗-->
		<div>
			<el-dialog
				v-model="addTypeDialogVisible"
				title="新增支护参数类型"
				width="30%"
				:before-close="handleClose"
			>
				<div style="text-align: center">
					<el-input style="width: 60%;margin-bottom: 5%" v-model="add_drawing_no" placeholder="请输入图号"/>
					<el-input style="width: 60%;margin-bottom: 5%" v-model="add_support_no" placeholder="请输入支护参数类型"/>
				</div>
				<template #footer>
                    <span class="dialog-footer">
                        <el-button @click="handleClose">取消</el-button>
                        <el-button type="primary" @click="add_new_type_but()"> 确认新增 </el-button>
                    </span>
				</template>
			</el-dialog>
		</div>
	<!--	新增图号弹窗-->
		<div>
			<el-dialog v-model="dialogAddDrawVisible" title="新增图号" :before-close="handleCloseAddDraw">
				<div style="margin-bottom: 2%">
					<el-tag style="margin-right: 2%">衬砌类型:</el-tag>
					<el-input placeholder="请输入图号" v-model="updataType" disabled style="width: 30%" size="small"/>
				</div>
				<div>
					<el-tag style="margin-right: 2%">图&emsp;&emsp;号:</el-tag>
					<el-input placeholder="请输入图号" v-model="updataDrawNo" style="width: 30%" size="small"/>
				</div>
				<template #footer>
          <span class="dialog-footer">
            <el-button size="small" @click="handleCloseAddDraw">取消</el-button>
            <el-button type="danger" size="small" @click="addDrwaNo"> 新增 </el-button>
          </span>
				</template>
			</el-dialog>
		</div>
		<!--	修改弹窗-->
		<div>
			<el-dialog v-model="dialogUpdataDrawVisible" title="修改" :before-close="handleCloseType">
				<div style="margin-bottom: 2%">
					<el-tag style="margin-right: 2%">衬砌类型:</el-tag>
					<el-input placeholder="请输入图号" v-model="updataType" style="width: 30%" size="small"/>
				</div>
				<div>
					<el-tag style="margin-right: 2%">图&emsp;&emsp;号:</el-tag>
					<el-input placeholder="请输入图号" v-model="updataDrawNo" style="width: 30%" size="small"/>
				</div>
				<template #footer>
          <span class="dialog-footer">
            <el-button size="small" @click="handleCloseType">取消</el-button>
            <el-button type="danger" size="small" @click="updataTypeCan"> 修改 </el-button>
          </span>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowTimes} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			addTypeDialogVisible: false,
			add_support_no: '',
			add_drawing_no: '',
			drawing_no: '',
			support_no: '',
			types: [],
			pagenum: 1,
			totals: 0,
			updataDrawNo: '',
			updataType: '',
			updataId: '',
			dialogAddDrawVisible: false,
			dialogUpdataDrawVisible: false
		}
	},
	created() {
		this.get_type()
	},
	methods: {
		get_type(){
			this.axios.get('/quantitieType/getQuantitieType', (response) => {
				this.types = response.obj.records;
				this.totals = response.obj.pages * 10;
			},{
				drawing_no: this.drawing_no,
				support_no: this.support_no,
				page_count: 1
			})
		},
		handleClose(){
			this.add_drawing_no = ''
			this.add_support_no = ''
			this.addTypeDialogVisible = false
			this.get_type()
		},
		add_new_type_but(){
			this.axios.post('/quantitieType/saveQuantitieType', (response) => {
				if (response.obj){
					this.add_drawing_no = ''
					this.add_support_no = ''
					this.get_type()
					ElMessage({
						message: '新增支护参数类型成功!',
						type: 'success'
					})
				}else {
					ElMessage.error('新增支护参数类型失败，请联系科信部!')
				}
			},{
				drawing_no: this.add_drawing_no,
				support_no: this.add_support_no,
				up_user: this.GET_USER,
				up_time: setNowTimes(new Date())
			})
		},
		del_message(item){
			this.axios.get('/quantitieTypeConfig/getTypeConfig', (response) => {
				if (response.obj.length === 0){
					this.axios.post('/quantitieType/removeQuantitieType', (response) => {
						if (response.obj){
							this.get_type()
							ElMessage({
								message: '删除支护参数类型成功!',
								type: 'success'
							})
						}else {
							ElMessage.error('删除支护参数类型失败，请联系科信部!')
						}
					},{
						id: item.id
					})
				}else {
					ElMessage.error('该衬砌类型已绑定配置，不允许删除!')
				}
			},{
				type_id: item.id
			})

		},
		updataDrawingNo(item){
			this.updataId = item.id
			this.updataType = item.supportNo
			this.dialogAddDrawVisible = true
		},
		updataTypeBut(item){
			this.updataId = item.id
			this.updataType = item.supportNo
			this.updataDrawNo = item.drawingNo
			this.dialogUpdataDrawVisible = true
		},
		handleCloseAddDraw(){
			this.dialogAddDrawVisible = false
			this.updataId = ''
			this.updataType = ''
			this.updataDrawNo = ''
		},
		handleCloseType(){
			this.dialogAddDrawVisible = false
			this.updataId = ''
			this.updataType = ''
			this.updataDrawNo = ''
		},
		addDrwaNo(){
			this.axios.post('/quantitieType/addDrawNo', (response) => {
				if (response.obj){
					ElMessage.success('新增成功!')
					this.reload()
				}else {
					ElMessage.error('新增失败!')
				}
			},{
				id: this.updataId,
				drawNo: this.updataDrawNo,
				supportNo: this.updataType,
				flag: 0
			})
		},
		updataTypeCan(){
			this.axios.post('/quantitieType/addDrawNo', (response) => {
				if (response.obj){
					ElMessage.success('修改成功!')
					this.reload()
				}else {
					ElMessage.error('修改失败!')
				}
			},{
				id: this.updataId,
				drawNo: this.updataDrawNo,
				supportNo: this.updataType,
				flag: 1
			})
		}
	},
	watch: {
		pagenum(){
			this.axios.get('/quantitieType/getQuantitieType', (response) => {
				this.types = response.obj.records;
				this.totals = response.obj.pages * 10;
			},{
				drawing_no: this.drawing_no,
				support_no: this.support_no,
				page_count: this.pagenum
			})
		},
	}
}
</script>

<style>

</style>