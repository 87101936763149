<template>
	<div>
		<!--选择图号-->
		<div>
			<el-tag style="margin-right: 1%;">图号:</el-tag>
			<el-select
				size="small"
				v-model="drawing_no"
				filterable
				@change="getTypeConfig"
				style="width: 30%"
			>
				<el-option
					v-for="item in drawing_nos"
					:key="item.id"
					:label="'图号:' + item.drawingNo + '->' + '衬砌类型:' + item.supportNo"
					:value="item.id"
				/>
			</el-select>
		</div>
	<!--	配置表格-->
		<div>
			<el-table :data="type_config" style="width: 100%">
				<el-table-column prop="configOneName" label="第一层" width="380" />
				<el-table-column prop="configTowName" label="第二层" width="380" />
				<el-table-column prop="configThreeName" label="第三层" width="380" />
				<el-table-column prop="configThreeUnit" label="单位" width="380" />
			</el-table>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {ElMessage} from "element-plus";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			drawing_no: '',
			drawing_nos: [],
			type_config: []
		}
	},
	created() {
		this.getAllDrawing()
	},
	methods: {
		// 获取所有的图号
		getAllDrawing(){
			this.axios.get('/quantitieType/getAllTypeByDrawNo', (response) => {
				this.drawing_nos = response.obj
			},{
				drawing_no: this.drawing_no
			})
		},
		getTypeConfig(){
			this.axios.get('/quantitieTypeConfig/getTypeConfig', (response) => {
				this.type_config = response.obj
			},{
				type_id: this.drawing_no
			})
		}
	}
}
</script>

<style>

</style>