<template>
	<div>
		<!--选择图号-->
		<div>
			<el-tag style="margin-right: 1%;">图号:</el-tag>
			<el-select
				size="small"
				v-model="drawing_no"
				filterable
				@change="getTypeConfig"
				style="width: 30%"
			>
				<el-option
					v-for="item in drawing_nos"
					:key="item.id"
					:label="'图号:' + item.drawingNo + '->' + '衬砌类型:' + item.supportNo"
					:value="item.id"
				/>
			</el-select>
		</div>
	<!--	选择文件-->
		<div style="margin-top: 2%;margin-left: 30%">
			<el-upload
				ref="upload"
				class="upload-demo"
				action="#"
				:http-request="upfile"
				:auto-upload="false"
				:data="dataup"
				:limit="1"
				:on-change="imgchange"
				:on-remove="handleRemove"
				:multiple="false"
			>
				<el-button type="primary" style="margin-left: 55%;margin-top: 2%">请选择附件</el-button>
			</el-upload>
		</div>
	<!--	提交-->
		<div style="margin-left: 33.5%;margin-top: 5%">
			<el-button type="danger" @click="import_config">上传并导入</el-button>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowTimes} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			drawing_no: '',
			drawing_nos: [],
			dataup: {
				file: '',
				userid: ''
			},
			fileList: [],
		}
	},
	created() {
		this.getAllDrawing()
	},
	methods: {
		// 获取所有的图号
		getAllDrawing(){
			this.axios.get('/quantitieType/getAllTypeByDrawNo', (response) => {
				this.drawing_nos = response.obj
			},{
				drawing_no: this.drawing_no
			})
		},
		imgchange(file) {
			if (this.fileList.length > 1){
				ElMessage.error('只可上传一个文件夹!')
			}else {
				this.fileList.push(file);
			}
			console.log(this.fileList.length)

		},
		handleRemove(file) {
			for (let i = 0; i < this.fileList.length; i++) {
				if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
					this.fileList.splice(i, 1)
				}
			}
		},
		upfile(){},
		import_config(){
			if (this.drawing_no === ''){
				ElMessage.error('请选择衬砌类型!')
			}else {
				this.dataup.file = this.fileList[0].raw
				this.axios.post('/quantitieImport/importData', (response) => {
					if (response.obj){
						ElMessage.success('导入成功');
						this.reload()
					}else {
						ElMessage.error('导入失败!')
					}
				},{
					file: this.dataup.file,
					up_user: this.GET_USER,
					up_time: setNowTimes(new Date()),
					up_bdst: this.GET_BDST,
					type_id: this.drawing_no
				})
			}
		}
	}
}
</script>

<style>

</style>