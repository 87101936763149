<template>
	<div>
		<div style="margin-top: 5%;display: flex;margin-left: 30%">
			<el-input placeholder="请输入第一层级名称" v-model="config_name" style="width: 30%"/>
			<el-button @click="saveConfig" style="margin-left: 2%" type="primary" >提交</el-button>
		</div>

	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			config_name: ''
		}
	},
	created() {
	},
	methods: {
		saveConfig(){
			this.axios.post('/quantiteConfig/saveConfig', (response) => {
				if (response.obj){
					ElMessage.success('添加成功!')
					this.config_name = ''
				}else {
					ElMessage.error('添加失败!')
				}
			},{
				name: this.config_name,
				flag: 1
			})
		}
	}
}
</script>

<style>

</style>