<template>
	<div>
		<!--选择图号-->
		<div>
			<el-tag style="margin-right: 1%;">图&emsp;号:</el-tag>
			<el-select
				size="small"
				v-model="drawing_no"
				filterable
				style="width: 30%"
			>
				<el-option
					v-for="item in drawing_nos"
					:key="item.id"
					:label="'图号:' + item.drawingNo + '->' + '衬砌类型:' + item.supportNo"
					:value="item.id"
				/>
			</el-select>
		</div>
	<!--	选择加宽值-->
		<div style="margin-top: 2%">
			<el-tag style="margin-right: 1%;">加宽值:</el-tag>
			<el-select
				size="small"
				v-model="widen_value"
				filterable
				@change="getConfigData"
				style="width: 30%"
			>
				<el-option
					v-for="item in widen_values"
					:key="item.label"
					:label="item.label"
					:value="item.value"
				/>
			</el-select>
		</div>
	<!--	数据-->
		<div>
			<el-table :data="config_data" style="width: 90%">
				<el-table-column prop="typeId" label="配置项" width="650" />
				<el-table-column prop="configNum" label="数值" width="250" />
				<el-table-column prop="widenValue" label="加宽值" width="150" />
			</el-table>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			drawing_no: '',
			drawing_nos: [],
			widen_value: '',
			widen_values: [
				{
					value: '0',
					label: '0'
				},
				{
					value: '10',
					label: '10'
				},
				{
					value: '20',
					label: '20'
				},
				{
					value: '30',
					label: '30'
				},
				{
					value: '40',
					label: '40'
				},
				{
					value: '50',
					label: '50'
				},
				{
					value: '60',
					label: '60'
				},
				{
					value: '70',
					label: '70'
				},
				{
					value: '80',
					label: '80'
				},
				{
					value: '90',
					label: '90'
				},
				{
					value: '100',
					label: '100'
				}
			],
			config_data: []
		}
	},
	created() {
		this.getAllDrawing()
	},
	methods: {
		// 获取所有的图号
		getAllDrawing(){
			this.axios.get('/quantitieType/getAllTypeByDrawNo', (response) => {
				this.drawing_nos = response.obj
			},{
				drawing_no: this.drawing_no
			})
		},
		getConfigData(){
			this.axios.get('/quantitieImport/getConfigData', (response) => {
				this.config_data = response.obj
			},{
				type_id: this.drawing_no,
				widen_value: this.widen_value
			})
		}
	}
}
</script>

<style>

</style>