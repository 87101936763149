<template>
	<div>
		<!--选择图号-->
		<div>
			<el-tag style="margin-right: 1%;">图号:</el-tag>
			<el-select
				size="small"
				v-model="drawing_no"
				filterable
				@change="getTypeConfig"
				style="width: 30%"
			>
				<el-option
					v-for="item in drawing_nos"
					:key="item.id"
					:label="'图号:' + item.drawingNo + '->' + '衬砌类型:' + item.supportNo"
					:value="item.id"
				/>
			</el-select>
			<el-button @click="dialogWidenVisible = true" type="danger" size="small" style="margin-left: 50%">提交</el-button>
		</div>
	<!--	填写表单-->
		<div style="margin-top: 2%">
			<el-form :model="form" label-width="520px">
				<el-form-item v-for="(item, index) in type_config" :label="item.configOneName + '->'
				+ item.configTowName + '->' + item.configThreeName + item.configThreeUnit">
					<el-input v-model="form.num[index]" style="width: 30%;"/>
				</el-form-item>
			</el-form>
		</div>
	<!--	加宽值-->
		<div>
			<el-dialog v-model="dialogWidenVisible" title="请输入加宽值" :before-close="handleClose">
				<div>
					<el-tag>加宽值:</el-tag>
					<el-input placeholder="请输入加宽值" v-model="widening_value" style="width: 20%" size="small"/>
				</div>
				<template #footer>
          <span class="dialog-footer">
            <el-button size="small" @click="handleClose">取消</el-button>
            <el-button type="danger" size="small" @click="saveNumConfig"> 提交 </el-button>
          </span>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowTimes} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			drawing_no: '',
			drawing_nos: [],
			type_config: [],
			form: {
				num: []
			},
			type_config_id: [],
			widening_value: '',
			dialogWidenVisible: false
		}
	},
	created() {
		this.getAllDrawing()
	},
	methods: {
		// 获取所有的图号
		getAllDrawing(){
			this.axios.get('/quantitieType/getAllTypeByDrawNo', (response) => {
				this.drawing_nos = response.obj
			},{
				drawing_no: this.drawing_no
			})
		},
		getTypeConfig(){
			this.type_config_id = []
			this.axios.get('/quantitieTypeConfig/getTypeConfig', (response1) => {
				this.type_config = response1.obj
				for (let i = 0; i < this.type_config.length; i++){
					this.type_config_id.push(this.type_config[i].id)
				}
			},{
				type_id: this.drawing_no
			})
		},
		saveNumConfig(){
			if (this.type_config_id.length !== this.form.num.length){
				ElMessage.error('数据有误，请联系科信部')
			}else {
				this.axios.get('/quantitieNum/getConfigNum', (response) => {
					if (response.obj){
						this.axios.post('/quantitieNum/saveNumConfig', (response1) => {
							if (response1.obj){
								ElMessage.success('配置成功!')
								this.reload()
							}else {
								ElMessage.error('配置失败，请联系科信部!')
							}
						},{
							type_id: this.drawing_no,
							type_config_id: this.type_config_id,
							num_value: this.form.num,
							up_user: this.GET_USER,
							up_bdst: this.GET_BDST,
							up_time: setNowTimes(new Date()),
							widening_value: this.widening_value
						})
					}
				},{
					type_id: this.drawing_no,
					widen_value: this.widening_value
				})
			}
		},
		handleClose(){
			this.dialogWidenVisible = false
			this.widening_value = ''
		}
	}
}
</script>

<style>

</style>