<!--工区管理-三工区算量-->
<template>
	<div>
		<div>
			<Header></Header>
		</div>
		<div id="admindiv1">
			<div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
					<el-breadcrumb-item style="font-size: 13px">基础信息管理</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div id="forms_div2">
			<el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
				<el-tab-pane label="支护参数管理" name="a"><Type_index v-if="a" /></el-tab-pane>
				<el-tab-pane label="支护数据导入" name="e"><Import_index v-if="e" /></el-tab-pane>
				<el-tab-pane label="支护数据查询" name="b"><Get_data v-if="b" /></el-tab-pane>
				<!--<el-tab-pane label="支护配置录入" name="e"><Config_index v-if="e" /></el-tab-pane>-->
				<!--<el-tab-pane label="支护配置查询" name="b"  ><Get_config v-if="b" /></el-tab-pane>-->
				<!--<el-tab-pane label="新增支护配置" name="c"><Add_type_config v-if="c" /></el-tab-pane>-->
				<!--<el-tab-pane label="支护数据录入" name="d"><Data_index v-if="d" /></el-tab-pane>-->
				<!--<el-tab-pane label="支护数据查询" name="f"><Data_get v-if="f" /></el-tab-pane>-->
			</el-tabs>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import Header from "@/views/header.vue";
import Type_index from "@/views/quantitie/type/index.vue"
import Config_index from "@/views/quantitie/config/config_index.vue";
import Get_config from "@/views/quantitie/config/get_config.vue";
import Add_type_config from "@/views/quantitie/config/add_type_config.vue";
import Data_index from "@/views/quantitie/data/data_index.vue";
import Data_get from "@/views/quantitie/data/data_get.vue";
import Import_index from "@/views/quantitie/import_data/import_index.vue";
import Get_data from "@/views/quantitie/import_data/get_data.vue";

export default {
	name: "quantitie_index",
	components: {Type_index, Header, Config_index, Get_config, Add_type_config, Data_index, Data_get, Import_index, Get_data},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			activeName: 'a',
			a: true,
			b: false,
			e: false,
			c: false,
			d: false,
			f: false
		}
	},
	created() {
		if (!this.$route.params.key){
			this.$router.push("/manage")
		}
		if (sessionStorage.getItem('quantitie_index_tab')){
			this.activeName = sessionStorage.getItem('quantitie_index_tab');
			if (this.activeName === 'a'){
				this.a = true
				this.b = false
				this.e = false
				this.d = false
				this.c = false
				this.f = false
			}
			if (this.activeName === 'b'){
				this.b = true
				this.a = false
				this.e = false
				this.d = false
				this.f = false
				this.c = false
			}
			if (this.activeName === 'e'){
				this.b = false
				this.a = false
				this.e = true
				this.d = false
				this.f = false
				this.c = false
			}
			if (this.activeName === 'c'){
				this.b = false
				this.a = false
				this.c = true
				this.e = false
				this.d = false
				this.f = false
			}
			if (this.activeName === 'd'){
				this.b = false
				this.a = false
				this.c = false
				this.e = false
				this.d = true
				this.f = false
			}
			if (this.activeName === 'f'){
				this.b = false
				this.a = false
				this.c = false
				this.e = false
				this.d = false
				this.f = true
			}
		}
	},
	methods: {
		handleClick(tab, event){
			sessionStorage.setItem('quantitie_index_tab', tab.props.name)
			if (tab.props.name === 'a'){
				this.a = true
				this.b = false
				this.e = false
				this.c = false
				this.d = false
				this.f = false
			}
			if (tab.props.name === 'b'){
				this.b = true
				this.a = false
				this.e = false
				this.c = false
				this.d = false
				this.f = false
			}
			if (tab.props.name === 'e'){
				this.b = false
				this.a = false
				this.e = true
				this.c = false
				this.d = false
				this.f = false
			}
			if (tab.props.name === 'c'){
				this.b = false
				this.a = false
				this.c = true
				this.e = false
				this.d = false
				this.f = false
			}
			if (tab.props.name === 'd'){
				this.b = false
				this.a = false
				this.c = false
				this.e = false
				this.d = true
				this.f = false
			}
			if (tab.props.name === 'f'){
				this.b = false
				this.a = false
				this.c = false
				this.e = false
				this.d = false
				this.f = true
			}
		},
		reall(){
			sessionStorage.removeItem('quantitie_index_tab');
		}
	}
}
</script>

<style>
#admindiv1{
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	justify-content: space-between;
}
#forms_div2{
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 95%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 500px;
}
</style>