<template>
	<div>
		<div>
			<el-button type="primary" @click="add(1)">新增第一层</el-button>
			<el-button type="warning" @click="add(2)">新增第二层</el-button>
			<el-button type="danger" @click="add(3)">新增第三层</el-button>
		</div>

		<div>
			<One v-if="flag === 1"/>
			<Two v-if="flag === 2"/>
			<Three v-if="flag === 3"/>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import One from "@/views/quantitie/config/add_type_config/one.vue";
import Two from "@/views/quantitie/config/add_type_config/two.vue";
import Three from "@/views/quantitie/config/add_type_config/three.vue";

export default {
	name: "",
	components: {One, Two, Three},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			flag: 1
		}
	},
	created() {
	},
	methods: {
		add(item){
			this.flag = item
		}
	}
}
</script>

<style>

</style>